<template>
  <PrimaryModal v-bind="$attrs" v-on="$listeners">
    <v-card-text>
      <v-select
          v-model="data"
          :items="options"
          outlined
          placeholder="請選擇"
          class="pb-5"
      ></v-select>

      <div class="d-flex justify-center">
        <v-btn color="primary" outlined class="flex-grow-1 mr-4" @click="close">取消</v-btn>
        <v-btn class="flex-grow-1" color="primary" @click="apply">確認</v-btn>
      </div>
    </v-card-text>
  </PrimaryModal>
</template>

<script lang="babel" type="text/babel">
import PrimaryModal from "components/modal/primaryModal.vue";
import modalMixins from "components/modal/modal";
export default {
  mixins: [modalMixins],
  props: {

  },
  components: {
    PrimaryModal,
  },
  data: () => ({
    data: null,
    options: [],
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
  },
  async created() {
    await this.getCanApplyStore()
  },
  methods: {
    async getCanApplyStore() {
      this.$store.dispatch('loading/active')
      try {
        const res = await this.$api.collection.applyPayApi.getCanApplyStore({
          provider: this.providerId,
        })
        this.options = res.map(item => ({text: item.name, value: item.store_id}))
      } catch(err) {
        console.error(err)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    apply() {
      this.$emit('apply', this.data)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>